import styled, { css } from 'styled-components'

export const Button = styled.button`
  width: 260px;
  height: 56px;
  background: #5b85f9;
  border-radius: 28px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 32px;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  align-self: flex-end;
  border: 0 none;
  color: #ffffff;

  ${(props: any) =>
    props.primary &&
    css`
      background: palevioletred;
      color: white;
    `}

  transition: all ease .2s;

  :hover {
    cursor: pointer;
    transform: scale(1.008);
    box-shadow: 0px 22px 22px rgba(0, 0, 0, 0.04);
  }
`
