import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import ReactTags from 'react-tag-autocomplete'
import { getInterests, getSkills } from '../api'
import { routes } from '../App'
import { useUserDetails } from '../context'
import { Interest, Skill, User } from '../interfaces'
import { Button } from '../styled/Button'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'

export const CompleteProfile2: React.FC = ({
  history,
  setUserDetails
}: any) => {
  const userDetails = useUserDetails()
  const [interests, setInterests] = useState<Interest[]>([])
  const [skills, setSkills] = useState<Skill[]>([])

  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    Promise.all([getSkills({ token }), getInterests({ token })]).then(
      ([{ data: skillData }, { data: interestData }]) => {
        setSkills(skillData)
        setInterests(interestData)
      }
    )
  }, [])

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Complete your profile</HeaderText>
      </OnboardingHeader>
      <Formik
        initialValues={{
          skills: userDetails!.skills || [],
          interests: userDetails!.interests || []
        }}
        validate={(values): User => {
          const errors: any = {}
          if (values.skills.length === 0) {
            errors.skills = 'Required'
          }
          if (values.interests.length === 0) {
            errors.postCode = 'Required'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            ...values
          }))
          history.push(routes.completeProfile3)
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting
        }: {
          values: {
            interests: Interest[]
            skills: Skill[]
          }
          setFieldValue: any
          isSubmitting: boolean
        }) => (
          <ContentContainer>
            <Form>
              <QuestionContainer data-testid="complete-skills-field">
                <QuestionTitle>skills</QuestionTitle>
                <ReactTags
                  tags={values.skills}
                  suggestions={skills}
                  placeholder="Choose some skills"
                  minQueryLength={0}
                  autofocus={false}
                  handleDelete={(interest: any) => {
                    const tags = values.skills.slice(0)
                    tags.splice(interest, 1)
                    setFieldValue('skills', tags)
                  }}
                  handleAddition={(skill: any) => {
                    setFieldValue('skills', [...values.skills, skill])
                  }}
                />
                <ErrorMessage name="skills" component="div" />
              </QuestionContainer>
              <QuestionContainer data-testid="complete-interests-field">
                <QuestionTitle>interests</QuestionTitle>
                <ReactTags
                  tags={values.interests || []}
                  suggestions={interests}
                  placeholder="Choose some interests"
                  minQueryLength={0}
                  autofocus={false}
                  handleDelete={(interest: any) => {
                    const tags = values.interests.slice(0)
                    tags.splice(interest, 1)
                    setFieldValue('interests', tags)
                  }}
                  handleAddition={(interest: any) => {
                    setFieldValue('interests', [...values.interests, interest])
                  }}
                />
                <ErrorMessage name="interests" component="div" />
              </QuestionContainer>
              <Button
                type="submit"
                disabled={isSubmitting}
                data-testid="complete-next"
              >
                Next
              </Button>
            </Form>
          </ContentContainer>
        )}
      </Formik>
    </div>
  )
}
