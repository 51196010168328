import styled from 'styled-components'

export const QuestionContainer = styled.div`
  min-height: 120px;
  max-width: 320px;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;

  .react-tags__suggestions {
    z-index: 99;
    left: unset;
    width: 100%;

    mark {
      padding: 0;
    }
  }
` 
