import styled from 'styled-components';

export const SomeCTAButton = styled.button<{
  width?: string;
}> `
  border: 0 none;
  box-sizing: border-box;
  color: white;
  width: ${props => props.width || '100%'};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  background: #5b85f9;
  border-radius: 46px;
  background: #5b85f9;
  padding: 8px;
  margin-top: 24px;
  max-width: 250px;

  transition: all ease .2s;

  :hover {
    cursor: pointer;
    transform: scale(1.008);
    box-shadow: 0px 22px 22px rgba(0, 0, 0, 0.04);
  }
`;

