import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import ReactTags from 'react-tag-autocomplete'
import {
  getInterests,
  getUserDetails,
  getSkills,
  updateUserDetails
} from '../api'
import { routes } from '../App'
import { Interest, Skill, User } from '../interfaces'
import { ContentContainer } from '../styled/ContentContainer'
import { HeaderText } from '../styled/HeaderText'
import { OnboardingHeader } from '../styled/OnboardingHeader'
import { QuestionContainer } from '../styled/QuestionContainer'
import { QuestionTitle } from '../styled/QuestionTitle'
import { USER_SUPPORT_LINK } from '../utils/constants';

export const MemberDashboardProfile: React.FC = ({ history }: any) => {
  const [interests, setInterests] = useState<Interest[]>([])
  const [userDetails, setUserDetails] = useState()
  const [skills, setSkills] = useState<Skill[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  useEffect(() => {
    const token = window.localStorage.getItem('Token')
    getUserDetails({ token }).then(({ data }) => {
      setUserDetails({
        jobTitle: data[0].job_title,
        company: data[0].company,
        skills: data[0].skills || [],
        interests: data[0].interests || [],
        aboutYou: data[0].about_you || '',
        mobileNumber: data[0].mobile_number,
        postcode: data[0].postcode
      })
    })
    getInterests({ token }).then(({ data }) => setInterests(data))
    getSkills({ token }).then(({ data }) => setSkills(data))
  }, [])

  if (!userDetails) {
    return <p>Loading</p>
  }

  return (
    <div className="App">
      <OnboardingHeader>
        <HeaderText>Your Details</HeaderText>
      </OnboardingHeader>
      <p>
        Issues? <a href={USER_SUPPORT_LINK}>Contact Us</a>
      </p>
      <Formik
        initialValues={{
          jobTitle: userDetails.jobTitle,
          company: userDetails.company,
          skills: userDetails.skills || [],
          interests: userDetails.interests || [],
          aboutYou: userDetails.aboutYou,
          mobileNumber: userDetails!.mobileNumber,
          postcode: userDetails!.postcode
        }}
        validate={(values): User => {
          const errors: any = {}
          if (!values.jobTitle) {
            errors.jobTitle = 'Required'
          }
          if (!values.company) {
            errors.company = 'Required'
          }
          if (values.skills.length === 0) {
            errors.skills = 'Required'
          }
          if (values.interests.length === 0) {
            errors.postCode = 'Required'
          }
          if (!values.aboutYou) {
            errors.aboutYou = 'Required'
          }
          if (!values.mobileNumber) {
            errors.mobileNumber = 'Required'
          }
          if (!values.postcode) {
            errors.postcode = 'Required'
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true)
          const token = window.localStorage.getItem('Token')
          return updateUserDetails(
            {
              about_you: values!.aboutYou,
              job_title: values!.jobTitle,
              mobile_number: values!.mobileNumber,
              postcode: values!.postcode,
              company: values!.company,
              //@ts-ignore
              interests: JSON.stringify(values!.interests),
              //@ts-ignore
              skills: JSON.stringify(values!.skills)
            },
            { token }
          )
            .then(() => {
              setSubmitting(false)
              history.push(routes.memberDashboardHome)
            })
            .catch(({ data }) => {
              setSubmitting(false)
              setErrorMessage(JSON.stringify(data))
            })
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <ContentContainer>
            <Form>
              <QuestionContainer>
                <QuestionTitle>mobile number</QuestionTitle>
                <span>
                  {'+61 '}
                  <Field
                    type="tel"
                    name="mobileNumber"
                    data-testid="onboarding-mobileNumber-field"
                    placeholder="Enter your mobile number"
                  />
                </span>
                <ErrorMessage name="mobileNumber" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>postcode</QuestionTitle>
                <Field
                  type="number"
                  name="postcode"
                  data-testid="onboarding-postcode-field"
                  placeholder="Enter your postcode"
                />
                <ErrorMessage name="postcode" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>job title</QuestionTitle>
                <Field
                  type="text"
                  name="jobTitle"
                  placeholder="Enter your job title"
                />
                <ErrorMessage name="jobTitle" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>company</QuestionTitle>
                <Field
                  type="text"
                  name="company"
                  placeholder="Enter your company"
                />
                <ErrorMessage name="company" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>skills</QuestionTitle>
                <ReactTags
                  tags={values.skills}
                  suggestions={skills}
                  minQueryLength={0}
                  autofocus={false}
                  placeholder="Choose some skills"
                  handleDelete={(interest: any) => {
                    const tags = values.skills.slice(0)
                    tags.splice(interest, 1)
                    setFieldValue('skills', tags)
                  }}
                  handleAddition={(skill: any) => {
                    setFieldValue('skills', [...values.skills, skill])
                  }}
                />
                <ErrorMessage name="skills" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>interests</QuestionTitle>
                <ReactTags
                  tags={values.interests || []}
                  suggestions={interests}
                  minQueryLength={0}
                  autofocus={false}
                  handleDelete={(interest: any) => {
                    const tags = values.interests.slice(0)
                    tags.splice(interest, 1)
                    setFieldValue('interests', tags)
                  }}
                  handleAddition={(interest: any) => {
                    setFieldValue('interests', [...values.interests, interest])
                  }}
                />
                <ErrorMessage name="interests" component="div" />
              </QuestionContainer>
              <QuestionContainer>
                <QuestionTitle>about you</QuestionTitle>
                <Field
                  type="text"
                  component="textarea"
                  name="aboutYou"
                  placeholder="Enter a short bio about yourself (optional)"
                />
                <ErrorMessage name="aboutYou" component="div" />
              </QuestionContainer>
              {/* <QuestionContainer>
                <QuestionTitle>profile picture</QuestionTitle>
                <Field
                  type="number"
                  name="profilePictureUrl"
                  placeholder="Upload a profile picture"
                />
                <ErrorMessage name="profilePictureUrl" component="div" />
              </QuestionContainer> */}
              {errorMessage === '' ? (
                <></>
              ) : (
                <p style={{ color: 'red' }}>{errorMessage}</p>
              )}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <button
                  style={{
                    padding: '8px 16px',
                    marginBottom: '16px',
                    borderRadius: '46px',
                    border: 'none',
                    fontSize: '18px',
                    color: 'white',
                    background: '#5B85F9'
                  }}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update Details
                </button>
              </div>
            </Form>
          </ContentContainer>
        )}
      </Formik>
    </div>
  )
}
